<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <brand-show :id="id" @gotAttrs="assignAttrs"></brand-show>
    <product-model-index
      :filtering="filtering"
      :ams_template="ams_template"
    ></product-model-index>
  </div>
</template>

<script>
import VuexBreadcrumb from './VuexBreadcrumb.vue'
import BrandShow from './BrandShow.vue'
import ProductModelIndex from './ProductModelIndex.vue'

export default {
  components: {
    VuexBreadcrumb,
    BrandShow,
    ProductModelIndex
  },
  props: {
    id: String
  },
  data () {
    return {
      filtering: {
        brand_ids: this.id
      },
      ams_template: {
        type: 'product-models',
        description: undefined,
        attributes: {
          'brand-id': this.id
        }
      },
      attrs: {},
    }
  },
  methods: {
    assignAttrs(attrs, description) {
      this.attrs = Object.assign( {}, attrs)
      this.ams_template.description = description + ' ' + this.attrs['name']
    }
  }
}
</script>