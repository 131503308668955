export const amsMofifiedAttrs = (obj1, obj2) =>
  Object.keys(obj1).reduce((acc,k) =>{
    if (obj1[k] !== obj2[k]) {
      acc[k.replace(/-/g, '_')] = obj1[k] // JSON to Rails convention
    }
    return acc
  }, {})

  export const deepEqual = (a,b) =>
  {
    if( (typeof a == 'object' && a != null) &&
        (typeof b == 'object' && b != null) )
    {
       let a_count = 0
       let b_count = 0
       a_count = Object.keys(a).length
       b_count = Object.keys(b).length
       if( a_count != b_count ) {return false}
       for( var a_key in a)
       {
         if(!(a_key in b) || !deepEqual(a[a_key],b[a_key])) {return false}
       }
       for( var b_key in b)
       {
         if(!(b_key in a) || !deepEqual(b[b_key],a[b_key])) {return false}
       }
       return true
    }
    else
    {
       return Object.is(a, b)
    }
  }
  // // descriptionArray
  // describe_belongs_to: [
  //   {
  //     prepend: '',
  //     type: 'brands',
  //     description_attr: 'name',
  //     append: ': '
  //   },
  //   {
  //     prepend: '',
  //     type: 'product-models',
  //     description_attr: 'name',
  //     append: ' '
  //   },
  //   {
  //     prepend: '#',
  //     type: 'product-listings',
  //     description_attr: 'edition',
  //     append: ' '
  //   },
  //   {
  //     prepend: 'copy(',
  //     type: 'product-items',
  //     description_attr: 'serial-number',
  //     append: ')'
  //   }
  // ]
  export const belongsToDescription = (item, includedArray, descriptionArray) => {
      function describe_relationship(obj) {
        let description = ''
        let included_obj = includedArray?.find( 
          el =>  
          el.type === obj.data.type && el.id === obj.data.id
        )
        let has_description = descriptionArray?.find(
          el =>
          el.type === obj.data.type
        )
        if (has_description && included_obj) {
          description +=  [
            has_description.prepend,
            `${included_obj.attributes[has_description.description_attr]}`,
            has_description.append
          ].filter(Boolean).join("")
          // filter(Boolean) to remove any falsies, undefined, nulls, etc.
        }
        let relationship_description = ''
        if (included_obj?.relationships) {
          Object.values(included_obj.relationships).forEach(relationship => 
            relationship_description = describe_relationship(relationship)
          )
          description = relationship_description + description
        }
        return description
      }
      let belong_description = ""
      if(item.relationships && descriptionArray) {
        Object.values(item.relationships).forEach(relationship => 
            belong_description += describe_relationship(relationship)
        )
      }
      return belong_description
    }
  