<template>
  <b-breadcrumb>
    <b-breadcrumb-item
      v-for="(item, index) in trail" :key="index"
      :to="item.navigation"
    >
      {{ item.name }}
    </b-breadcrumb-item>
  </b-breadcrumb>
</template>

<script>
import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        trail: 'query_path/trail'
      })
    }
    // data() {
    //   return {
    //     items: [
    //       {
    //         text: 'Admin',
    //         href: '#'
    //       },
    //       {
    //         text: 'Manage',
    //         href: '#'
    //       },
    //       {
    //         text: 'Library',
    //         active: true
    //       }
    //     ]
    //   }
    // }
  }
</script>