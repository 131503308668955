<template>
  <b-row>
    <b-col>
      <b-button-group vertical>
        <b-button size="sm"
          variant="primary" 
          class="mb-2"
          v-b-tooltip.hover.right title="Go Update"
          @click="$emit('update')"
        >
          <b-icon-triangle aria-label="Update"></b-icon-triangle>
        </b-button>
        <!-- <b-button size="sm"
          variant="primary" 
          class="mb-2"
          v-b-tooltip.hover.right title="Go View"
          @click="$emit('show')"
        >
          <b-icon-eye-fill aria-label="View"></b-icon-eye-fill>
        </b-button> -->
        <b-button size="sm"
          variant="primary"
          class="mb-2"
          v-b-tooltip.hover.right title="Cancel / Dismiss"
          @click="$emit('cancel')"
        >
          <b-icon-x-circle-fill>aria-label="Cancel"></b-icon-x-circle-fill>
        </b-button>
        <b-button size="sm"
          variant="primary"
          class="mb-2"
          v-b-tooltip.hover.right title="Help"
          @click="$emit('help')"
        >
          <b-icon-question-circle-fill> aria-label="Help"></b-icon-question-circle-fill>
        </b-button>
      </b-button-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  // BIconEyeFill,
  BIconXCircleFill,
  BIconTriangle,
  BIconQuestionCircleFill,
} from 'bootstrap-vue'
export default {
  components: {
    // BIconEyeFill,
    BIconXCircleFill,
    BIconTriangle,
    BIconQuestionCircleFill
  }
}
</script>
